import { Event } from '../../graphql/types.gen'
import { NavButtonConfig } from '../../components/global/Header/navConfig'

export function createEventOrdersPath(slug: Event['slug']) {
  return `/event/${slug}/orders`
}

export function createEventSummaryPath(slug: Event['slug']) {
  return `/event/${slug}/summary`
}

export function createScanTicketsPath(slug: Event['slug']) {
  return `/event/${slug}/scan`
}

export const myTicketsNavButtonConfig: NavButtonConfig = {
  iconName: 'fa fa-ticket',
  label: 'Tickets',
  route: '/tickets',
  prefetch: 'render',
  authenticated: true,
  overflow: true,
}
